import Logo from './logo.bmp';
import { Config } from '../../interfaces/config.interface';

const config: Config = {
  logo: Logo,
  title: 'Actividades de voluntariado',
  name: 'OHANA',
};

export default config;
